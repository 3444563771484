import { InputBatch } from '@/models/input-batch';
import store from '@/store';

export default class BatchesExportComponent {
	private cellsForHeader = [
		'University name',
		'University ID',
		'Upload time',
		'Type',
		'Intake year',
		'Intake month',
		'Total emails',
		'Matches',
		'Status'
	];

	public onExportClicked(): void {
		const exportResult = this.generateExport();
		if (!exportResult) {
			return;
		}

		this.downloadExport(exportResult);
	}

	private downloadExport(exportResult: Blob): void {
		const url = window.URL.createObjectURL(exportResult);
		const temporaryLink = this.getDownloadLink(url);

		document.body.appendChild(temporaryLink);
		temporaryLink.click();

		window.URL.revokeObjectURL(url);
		document.body.removeChild(temporaryLink);
	}

	private getDownloadLink(url: string): HTMLElement {
		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;

		const dateString = new Date(Date.now()).toUTCString();
		link.download = `Student Matching Dashboard input batches ${dateString}.csv`;

		return link;
	}

	private generateExport(): Blob {
		const exportRows = this.createExportRows();
		const exportContent = this.writeToString(exportRows);

		return new Blob([exportContent], { type: 'text/csv;charset=UTF-8' });
	}

	private writeToString(rows: string[][]): string {
		// Separate cells with semicolons, but also remove new-line characters that were left.
		return `${rows.map((e) => e.join(';').split('\n').join(' ')).join('\n')}`;
	}

	private createExportRows(): string[][] {
		const inputBatches = store.getters.inputBatches();
		const header = this.cellsForHeader;
		const lines = inputBatches.map(this.createCellsForBatch.bind(this));

		return [header, ...lines];
	}

	private createCellsForBatch(inputBatch: InputBatch): string[] {
		return [
			inputBatch.organisationName,
			inputBatch.organisationIdentity,
			inputBatch.creationDateTimeString,
			inputBatch.recruitmentStage.name,
			inputBatch.intakeYear.toString(),
			inputBatch.intakeMonth.name,
			inputBatch.inputSize.toString(),
			inputBatch.matchesCount.toString(),
			this.determineBatchStatus(inputBatch)
		];
	}

	private determineBatchStatus(inputBatch: InputBatch): string {
		if (inputBatch.canInputBatchBeApproved) {
			return '';
		}

		return inputBatch.status.name;
	}
}
