import { computed, Ref, ref } from 'vue';
import { ComplexModal } from '@studyportals/modal';
import { ModalStatusApproveBatchEnum } from '@/models/modal-status-approve-batch-enum';
import { InputBatchConfirmationTriggeredEvent } from '@/event-bus/input-batch-confirmation-triggered-event';
import store from '@/store';
import EventBus from '@/event-bus/event-bus';

export default class ApproveBatchModalComponent {
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	public modalStatusIsApprovalConfirmation = computed((): boolean => {
		return this.modalStatus.value === ModalStatusApproveBatchEnum.APPROVAL_CONFIRMATION;
	});

	public modalStatusIsDiscardConfirmation = computed((): boolean => {
		return this.modalStatus.value === ModalStatusApproveBatchEnum.DISCARD_CONFIRMATION;
	});

	public modalStatusIsLoading = computed((): boolean => {
		return this.modalStatus.value === ModalStatusApproveBatchEnum.LOADING;
	});

	public modalStatusIsSuccess = computed((): boolean => {
		return this.modalStatus.value === ModalStatusApproveBatchEnum.SUCCESS;
	});

	public modalStatusIsFailed = computed((): boolean => {
		return this.modalStatus.value === ModalStatusApproveBatchEnum.FAILED;
	});

	private modalStatus = computed((): ModalStatusApproveBatchEnum => {
		return store.getters.modalStatus();
	});

	public mounted(): void {
		this.prepareModal();
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
	}

	public onActionConfirmed(): void {
		EventBus.getEvent(InputBatchConfirmationTriggeredEvent).publish();
	}

	public hideModal(): void {
		store.mutations.setModalStatus(ModalStatusApproveBatchEnum.INACTIVE);
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'ApproveBatchModalWrapper',
			destroyOnClose: true,
			onClose: this.hideModal.bind(this)
		});

		window.ModalManager.open(this.modal.value);
	}
}
