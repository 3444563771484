import { defineComponent, Ref, toRef } from 'vue';
import { IComponentParams } from '@/models/i-component-params';

export default defineComponent({
	props: {
		params: Object
	},
	setup: (props) => {
		const params = toRef(props, 'params') as Ref<IComponentParams>;

		return {
			inputBatch: params.value.data
		};
	}
});
