import contractDatabaseClient from '@/infrastructure/ccdb-client';
import rollbarOperator from '@/infrastructure/rollbar-operator';

import { ICCDBContract } from '@studyportals/sp-lord-business-interface/src/CCDB/ICCDBContract';
import { InputBatch } from '@/models/input-batch';
import { InputBatchStatus } from '@studyportals/sp-lurch-interface/src/input-batches/input-batch-status';
import { CCDBNullContract } from '@studyportals/sp-lord-business-interface';

export default class WorkOrderMessagePresenter {
	// The primary organisation ids get priority in terms of setting the work order detail messages.
	private primaryUniqueOrganisationIds: string[] = [];
	private secondaryUniqueOrganisationIds: string[] = [];
	private successfulRetrievalCount = 0;
	private unsuccessfulRetrievalCount = 0;

	constructor(private readonly batches: InputBatch[]) {
		this.getUniqueOrganisationIds(true);
		this.getUniqueOrganisationIds(false);
	}

	public async setWorkOrderIncompleteMessages(rerenderMethod: () => void): Promise<void> {
		await this.setWorkOrderIncompleteMessagesForList(true, rerenderMethod);
		await this.setWorkOrderIncompleteMessagesForList(false, rerenderMethod);
	}

	private getUniqueOrganisationIds(isPrimary: boolean): void {
		this.batches.forEach((batch) => {
			const relevantList = isPrimary ? this.primaryUniqueOrganisationIds : this.secondaryUniqueOrganisationIds;
			if (this.shouldOrganisationIdBeOmittedFromUniqueList(batch, isPrimary)) {
				return;
			}

			relevantList.push(batch.organisationIdentity);
		});
	}

	private shouldOrganisationIdBeOmittedFromUniqueList(batch: InputBatch, isPrimary: boolean): boolean {
		// For either list, ids that are already in the primary list don't have to be added again.
		if (this.primaryUniqueOrganisationIds.includes(batch.organisationIdentity)) {
			return true;
		}

		// For the secondary list, ids that are already in the secondary list don't have to be added again.
		if (!isPrimary) {
			return this.secondaryUniqueOrganisationIds.includes(batch.organisationIdentity);
		}

		// For the primary list, ids of uploads that are already approved or discarded should be omitted.
		return batch.status.value !== InputBatchStatus.SUBMITTED;
	}

	private async setWorkOrderIncompleteMessagesForList(isPrimary: boolean, rerenderMethod: () => void): Promise<void> {
		const relevantList = isPrimary ? this.primaryUniqueOrganisationIds : this.secondaryUniqueOrganisationIds;
		for (const id of relevantList) {
			await this.setMessageForBatchesOfOrganisation(id);
		}

		if (!isPrimary && this.successfulRetrievalCount === 0 && this.unsuccessfulRetrievalCount > 0) {
			rollbarOperator.triggerError(
				'No work order data could be retrieved for any of the organisations for which input batches were found.'
			);
		}

		/* When the message is set for the first university in the list, the table gets updated automatically,
		but for the ones after that it doesn't, so a force rerender in between processing the lists is necessary. */
		rerenderMethod();
	}

	private async setMessageForBatchesOfOrganisation(organisationId: string): Promise<void> {
		const contract = await contractDatabaseClient.retrieveWorkOrderByOrganisationId(organisationId);
		const message = this.getFullSentenceForWorkOrder(contract);
		if (contract instanceof CCDBNullContract) {
			this.unsuccessfulRetrievalCount++;
		} else {
			this.successfulRetrievalCount++;
		}

		this.batches
			.filter((batch) => batch.organisationIdentity === organisationId)
			.forEach((batch) => batch.setWorkOrderToolTipMessage(message));
	}

	private getFullSentenceForWorkOrder(contract: ICCDBContract): string {
		if (contract.isComplete) {
			return '';
		}

		let workOrderMessage = 'Reasons for incomplete work order: ';

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const missingData: { [key: string]: boolean } = contract.getMissingData();
		const missingProperties = Object.keys(missingData);

		if (missingProperties.length === 0) {
			workOrderMessage += 'work order missing';
		}

		missingProperties.forEach(
			(property: string, index: number) =>
				(workOrderMessage += this.getSentencePartForPropertyOfWorkOrder(missingData, missingProperties, property, index))
		);

		return `${workOrderMessage}.`;
	}

	private getSentencePartForPropertyOfWorkOrder(
		missingData: { [key: string]: boolean },
		missingProperties: string[],
		property: string,
		index: number
	): string {
		let sentencePart = '';

		if (missingData[property] !== false) {
			return sentencePart;
		}

		if (missingProperties.length !== 1 && index === missingProperties.length - 1) {
			sentencePart += ' and ';
		} else if (index !== 0) {
			sentencePart += ', ';
		}

		sentencePart += this.getMissingWorkOrderPropertyDescription(property);

		return sentencePart;
	}

	private getMissingWorkOrderPropertyDescription(property: string): string {
		switch (property) {
			case 'contractID':
				return 'missing contract id';
			case 'organisationID':
				return 'missing organisation id';
			case 'psmId':
				return 'no assigned PSM';
			case 'notInScopeReason':
				return 'missing not-in-scope reason';
			case 'psmName':
				return 'missing name of assigned PSM';
			case 'promotionStartDate':
				return 'missing promotion start date';
			default:
				return 'unknown reason';
		}
	}
}
