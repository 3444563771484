import { defineComponent, onMounted, onUnmounted } from 'vue';
import ApproveBatchModalComponent from '@/presentation/components/approve-batch-modal/approve-batch-modal-class';

export default defineComponent({
	setup: () => {
		const component = new ApproveBatchModalComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			modalContent: component.modalContent,
			modalStatusIsApprovalConfirmation: component.modalStatusIsApprovalConfirmation,
			modalStatusIsDiscardConfirmation: component.modalStatusIsDiscardConfirmation,
			modalStatusIsLoading: component.modalStatusIsLoading,
			modalStatusIsFailed: component.modalStatusIsFailed,
			modalStatusIsSuccess: component.modalStatusIsSuccess,
			onActionConfirmed: component.onActionConfirmed.bind(component),
			hideModal: component.hideModal.bind(component)
		};
	}
});
