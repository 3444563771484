import { Component, defineComponent, onMounted, onUnmounted, watch } from 'vue';
import ApproveBatchComponent from '@/presentation/components/approve-batch/approve-batch-class';
import ApproveBatchModal from '@/presentation/components/approve-batch-modal/approve-batch-modal.vue';

export default defineComponent({
	components: {
		ApproveBatchModal: ApproveBatchModal as Component
	},
	setup: () => {
		const component = new ApproveBatchComponent();

		watch(component.modalStatus, component.onModalStatusChanged.bind(component));

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			approveBatchModalStatusIsInactive: component.approveBatchModalStatusIsInactive
		};
	}
});
