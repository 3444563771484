import { UTCDateTime } from '@studyportals/mb-platform-date-time';
import { InputBatchStatus as InputBatchStatusEnum } from '@studyportals/sp-lurch-interface';
import { IntakeStageType as RecruitmentStageEnum } from '@studyportals/sp-lurch-interface';
import { InputBatchStatus } from './input-batch-status';
import { Month } from './month';
import { RecruitmentStage } from './recruitment-stage';

export class InputBatch {
	public get creationDateTimeString(): string {
		const date = this.creationTime.toShortDateString();
		const hours = this.creationTime.getHours().toString().padStart(2, '0');
		const minutes = this.creationTime.getMinutes().toString().padStart(2, '0');
		return `${date} (${hours}:${minutes})`;
	}

	private workOrderMessageSet = false;
	private workOrderMessage = '';

	public constructor(
		public readonly identity: string,
		public readonly creationTime: UTCDateTime,
		public readonly organisationIdentity: string,
		public readonly organisationName: string,
		public readonly intakeMonth: Month,
		public readonly intakeYear: number,
		public readonly recruitmentStage: RecruitmentStage,
		public readonly inputSize: number,
		public readonly matchesCount: number,
		private batchStatus: InputBatchStatus
	) { }

	public get status(): InputBatchStatus {
		return this.batchStatus;
	}

	public get canInputBatchBeApproved(): boolean {
		return this.status.value === InputBatchStatusEnum.SUBMITTED
			&& this.recruitmentStage.value === RecruitmentStageEnum.ENROLMENT;
	}

	public get isWorkOrderComplete(): boolean {
		return this.workOrderMessage === '';
	}

	public get workOrderTooltipMessage(): string {
		return this.workOrderMessage;
	}

	public get hasWorkOrderMessageBeenSet(): boolean {
		return this.workOrderMessageSet;
	}

	public updateStatus(status: InputBatchStatusEnum): void {
		this.batchStatus = InputBatchStatus.obtain(status);
	}

	public setWorkOrderToolTipMessage(message: string): void {
		this.workOrderMessage = message;
		this.workOrderMessageSet = true;
	}
}
