import { EmboBusiness } from '@/infrastructure';
import { OrganisationSearchResult } from './organisation-search-result';
import { Organisation } from '@/models/organisation';
import { IOrganisation } from '@studyportals/sp-millennium-falcon';
import { OrganisationNotFoundResult } from './organisation-not-found-result';

class OrganisationPresenter {
	public async searchForIdentity(identity: string): Promise<OrganisationSearchResult> {
		try {
			const organisations = await EmboBusiness.getOrganisations([identity]);

			if (0 === organisations.length) {
				return new OrganisationNotFoundResult();
			}

			const firstOrganisation = this.reconstituteOrganisation(organisations[0]);

			return new OrganisationSearchResult(firstOrganisation);
		} catch {
			return new OrganisationNotFoundResult();
		}
	}

	private reconstituteOrganisation(input: IOrganisation): Organisation {
		return new Organisation(input.id, input.title);
	}
}

export default new OrganisationPresenter();
