import { UserSession } from '@/infrastructure';
import { BaseSuperAgentRequestFactory, SignedRequestSender } from '@studyportals/mb-platform-http-requests';
import { SessionTokenRequestSigner } from '@studyportals/client-internal-platform-sso';
import { CCDBClient } from '@studyportals/sp-lord-business-interface/src/CCDB/CCDBClient';

const requestSigner = new SessionTokenRequestSigner(UserSession.userSessionOperations);
const requestSender = new SignedRequestSender(requestSigner);
const baseUrl = webpackDefinitions.VUE_APP_LORD_BUSINESS_URL;

const contractDatabaseClient = new CCDBClient(new BaseSuperAgentRequestFactory(), requestSender, baseUrl);

export default contractDatabaseClient;
