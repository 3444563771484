// NOTE: fits 8px grid
const columnWidthPxXXL = 240;
const columnWidthPxXL = 160;
const columnWidthPxL = 104;

export default [
	{
		field: 'organisationName',
		headerName: 'University name',
		headerTooltip: 'University name',
		tooltipField: 'organisationName',
		width: columnWidthPxXXL,
		cellClass: 'TableCell TableContentCell',
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'organisationIdentity',
		headerName: 'University ID',
		headerTooltip: 'University ID',
		tooltipField: 'organisationIdentity',
		width: columnWidthPxXXL,
		cellClass: 'TableCell TableContentCell',
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'creationDateTimeString',
		headerName: 'Upload time',
		headerTooltip: 'Upload time',
		tooltipField: 'creationDateTimeString',
		width: columnWidthPxXXL,
		cellClass: 'TableCell TableContentCell',
		headerClass: 'TableHeaderCell',
		sort: 'desc',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'recruitmentStage.name',
		headerName: 'Type',
		headerTooltip: 'Type',
		tooltipField: 'recruitmentStage.name',
		width: columnWidthPxL,
		cellClass: 'TableCell TableContentCell',
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'intakeYear',
		headerName: 'Intake year',
		headerTooltip: 'Intake year',
		tooltipField: 'intakeYear',
		width: columnWidthPxXL,
		cellClass: 'TableCell TableContentCell',
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'intakeMonth.name',
		headerName: 'Intake month',
		headerTooltip: 'Intake month',
		tooltipField: 'intakeMonth.name',
		width: columnWidthPxXL,
		cellClass: 'TableCell TableContentCell',
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'inputSize',
		headerName: 'Total emails',
		headerTooltip: 'Total emails',
		tooltipField: 'inputSize',
		width: columnWidthPxXL,
		cellClass: 'TableCell TableContentCell',
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'matchesCount',
		headerName: 'Matches',
		headerTooltip: 'Number of matches',
		tooltipField: 'matchesCount',
		width: columnWidthPxXL,
		cellClass: 'TableCell TableContentCell',
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		cellRenderer: 'ApproveBatchEntry',
		headerName: 'Status',
		headerTooltip: 'Status',
		width: columnWidthPxXXL,
		cellClass: 'TableCell TableActionCell',
		headerClass: 'TableHeaderCell'
	},
	{
		cellRenderer: 'WorkOrderDetails',
		headerName: 'Work order details',
		headerTooltip: 'Work order details',
		tooltipField: 'workOrderTooltipMessage',
		width: columnWidthPxXXL,
		cellClass: 'TableCell TableActionCell',
		headerClass: 'TableHeaderCell',
		tooltipComponent: 'customTooltip'
	}
];
