import { Organisation } from '@/models/organisation';

export class OrganisationSearchResult {
	private _organisation: Organisation | undefined;

	public get organisation(): Organisation {
		if (undefined === this._organisation) {
			throw new Error('The organisation could not be found');
		}

		return this._organisation;
	}

	public constructor(organisation?: Organisation) {
		this._organisation = organisation;
	}

	public hasOrganisation(): boolean {
		return undefined !== this._organisation;
	}
}
