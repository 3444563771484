import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "ApproveBatchEntry" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.inputBatch.canInputBatchBeApproved)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("button", {
            class: "DriverButton Green",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onApproveBatchClicked()))
          }, " Approve "),
          _createElementVNode("button", {
            class: "Textlink DiscardButton",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDiscardBatchClicked()))
          }, " Discard ")
        ], 64 /* STABLE_FRAGMENT */))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.inputBatch.status.name), 1 /* TEXT */))
  ]))
}