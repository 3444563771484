import { defineComponent, Ref, toRef } from 'vue';
import { ICustomComponentParams } from '@/models/i-component-params';
import IncompleteWorkOrderTooltipComponent
	from '@/presentation/components/incomplete-work-order-tooltip/incomplete-work-order-tooltip-class';

export default defineComponent({
	props: {
		params: Object
	},
	setup: (props) => {
		const params = toRef(props, 'params') as Ref<ICustomComponentParams>;
		const component = new IncompleteWorkOrderTooltipComponent(params);

		return {
			workOrderTooltipMessage: component.workOrderTooltipMessage
		};
	}
});
