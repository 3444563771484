import { Event } from '@/event-bus/event';

type EventConstructor<TEvent> = new () => TEvent;

class EventBus {
	private readonly events: Event[] = [];

	public getEvent<TEvent extends Event>(constructor: EventConstructor<TEvent>): TEvent {
		const event = this.events.find((_) => _ instanceof constructor) as TEvent;

		return event ? event : this.registerEvent(constructor);
	}

	private registerEvent<TEvent extends Event>(constructor: EventConstructor<TEvent>): TEvent {
		const event = new constructor();

		this.events.push(event);

		return event;
	}
}

export default new EventBus();
