import state from './state';
import { IGetters } from '@/models/store/getters.interface';

const getters: IGetters = {
	inputBatches: () => {
		return state.inputBatches;
	},
	filteredInputBatches: () => {
		return state.filteredInputBatches;
	},
	selectedInputBatch: () => {
		return state.selectedInputBatch;
	},
	modalStatus: () => {
		return state.modalStatus;
	},
	loadingDetails: () => {
		return state.loadingDetails;
	},
	inputBatchSearchQuery: () => {
		return state.inputBatchSearchQuery;
	},
	standAloneLogin: () => {
		return state.standAloneLogin;
	}
};

export default getters;
