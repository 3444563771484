import lurchClient from '@/infrastructure/lurch-client';

class InputBatchService {
	public async approveInputBatch(inputBatchIdentity: string): Promise<void> {
		await lurchClient.approveInputBatch(inputBatchIdentity);
	}

	public async discardInputBatch(inputBatchIdentity: string): Promise<void> {
		await lurchClient.discardInputBatch(inputBatchIdentity);
	}
}

export default new InputBatchService();
