import { defineComponent } from 'vue';
import BatchesExportComponent from '@/presentation/components/batches-export/batches-export-class';

export default defineComponent({
	setup: () => {
		const component = new BatchesExportComponent();

		return {
			onExportClicked: component.onExportClicked.bind(component)
		};
	}
});
