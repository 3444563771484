import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheBatchesOverview = _resolveComponent("TheBatchesOverview")!
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!
  const _component_LogOut = _resolveComponent("LogOut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loadingFinished)
      ? (_openBlock(), _createBlock(_component_TheBatchesOverview, { key: 0 }))
      : (_ctx.loadingStarted)
        ? (_openBlock(), _createBlock(_component_LoadingAnimation, { key: 1 }))
        : _createCommentVNode("v-if", true),
    (_ctx.standAloneLogin)
      ? (_openBlock(), _createBlock(_component_LogOut, { key: 2 }))
      : _createCommentVNode("v-if", true)
  ]))
}