import { computed } from 'vue';
import { ILoadingDetails } from '@/models/i-loading-details';
import store from '@/store';

export default class AppComponent {
	public loadingStarted = computed((): boolean => {
		return this.loadingDetails.value.loadingStarted;
	});

	public loadingFinished = computed((): boolean => {
		const details = this.loadingDetails.value;
		return this.loadingStarted.value && details.batchesLoaded === details.batchesTotal;
	});

	private loadingDetails = computed((): ILoadingDetails => {
		return store.getters.loadingDetails();
	});

	public standAloneLogin = computed((): boolean => {
		return store.getters.standAloneLogin();
	});
}
