import { createApp, h } from 'vue';
import { Rollbar } from '@/models/rollbar.interface';
import store from '@/store';
import App from './presentation/app.vue';
import LogIn from './stand-alone/log-in/log-in.vue';
import rollbarOperator from '@/infrastructure/rollbar-operator';
import stylesheetLoader from '@/stylesheet-loader';
import { bootstrap } from '@/bootstrapper';
const standAloneLogin = webpackDefinitions.STAND_ALONE_LOGIN === 'true';

import { ModalManager } from '@studyportals/modal';
import { IModalManager } from '@/models/i-modal-manager';

declare global {
	interface Window {
		ModalManager: IModalManager;
		Rollbar?: Rollbar;
	}
}

const url = webpackDefinitions.VUE_APP_ICONSET_URL;
stylesheetLoader.loadStylesheet(url);

store.mutations.subscribeToEventBus();
store.mutations.setStandAloneLogin(standAloneLogin);
window.ModalManager = new ModalManager();

try {
	bootstrap();

	const app = createApp({
		render: () => {
			return h(App);
		}
	});

	rollbarOperator.handleVueErrorsAndWarnings(app);
	app.mount('#app');
} catch (error: any) {
	console.error(error);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	if (standAloneLogin && error.name === 'NotAuthorizedException') {
		const app = createApp({
			render: () => {
				return h(LogIn);
			}
		});
		app.mount('#app');
	}
}
