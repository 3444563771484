import { UTCDateTime } from '@studyportals/mb-platform-date-time';
import { IInputBatch } from '@studyportals/sp-lurch-interface';
import { InputBatch } from '@/models/input-batch';
import { InputBatchStatus } from '@/models/input-batch-status';
import { Month } from '@/models/month';
import { RecruitmentStage } from '@/models/recruitment-stage';
import { IOrganisationDetails } from '@/models/i-organisation-details';
import { OrganisationNotFoundResult } from '@/presenters/organisation-not-found-result';
import OrganisationPresenter from '@/presenters/organisation-presenter';
import store from '@/store';

class InputBatchFactory {
	public async reconstituteInputBatches(input: readonly IInputBatch[]): Promise<InputBatch[]> {
		const organisationDetails = await this.prepareOrganisationNamesOfInputBatches(input);
		return input.map((batch) => this.reconstitute(batch, organisationDetails));
	}

	public reconstitute(input: IInputBatch, details: IOrganisationDetails): InputBatch {
		return new InputBatch(
			input.identity,
			UTCDateTime.fromISOString(JSON.parse(input.creationTimeIso)),
			input.organisationIdentity,
			details[input.organisationIdentity] ? details[input.organisationIdentity] : '',
			Month.forValue(input.intakeMonth),
			input.intakeYear,
			RecruitmentStage.obtain(input.targetIntakeStageType),
			input.inputSize,
			input.matchesCount,
			InputBatchStatus.obtain(input.status)
		);
	}

	private async prepareOrganisationNamesOfInputBatches(batches: readonly IInputBatch[]): Promise<IOrganisationDetails> {
		const organisationIds = batches.map((batch) => batch.organisationIdentity);
		const uniqueOrganisationIds = organisationIds.filter((id, index) => organisationIds.indexOf(id) === index);

		store.mutations.markLoadingAsStarted();
		store.mutations.setTotalAmountOfLoadedBatches(uniqueOrganisationIds.length);

		const details: IOrganisationDetails = {};
		let count = 0;
		for (const id of uniqueOrganisationIds) {
			let organisationSearchResult = await OrganisationPresenter.searchForIdentity(id);
			// If the organisation was not found, try once more.
			if (organisationSearchResult instanceof OrganisationNotFoundResult) {
				organisationSearchResult = await OrganisationPresenter.searchForIdentity(id);
			}
			details[id] = organisationSearchResult.hasOrganisation() ? organisationSearchResult.organisation.name : '';
			store.mutations.updateAmountOfLoadedBatches(++count);
		}

		return details;
	}
}

export default new InputBatchFactory();
