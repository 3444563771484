import lurchClient from '@/infrastructure/lurch-client';
import inputBatchFactory from '@/models/input-batch-factory';
import store from '@/store';

class InputBatchPresenter {
	public async acquireInputBatches(): Promise<void> {
		const acquiredInputBatches = await lurchClient.retrieveAllInputBatches();

		const inputBatches = await inputBatchFactory.reconstituteInputBatches(acquiredInputBatches);
		store.mutations.updateInputBatches(inputBatches);
		store.mutations.filterInputBatches();
	}
}

export default new InputBatchPresenter();
