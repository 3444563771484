import { computed, ref } from 'vue';
import { InputBatchSelection } from '@/models/input-batch-selection';
import { ModalStatusApproveBatchEnum } from '@/models/modal-status-approve-batch-enum';
import { InputBatchStatus as InputBatchStatusEnum } from '@studyportals/sp-lurch-interface/src/input-batches/input-batch-status';
import { InputBatchApprovalStartedEvent } from '@/event-bus/input-batch-approval-started-event';
import { InputBatchDiscardStartedEvent } from '@/event-bus/input-batch-discard-started-event';
import { InputBatchConfirmationTriggeredEvent } from '@/event-bus/input-batch-confirmation-triggered-event';
import { InputBatchApprovedEvent } from '@/event-bus/input-batch-approved-event';
import { InputBatchDiscardedEvent } from '@/event-bus/input-batch-discarded-event';
import store from '@/store';
import EventBus from '@/event-bus/event-bus';
import inputBatchService from '@/services/input-batch-service';

export default class ApproveBatchComponent {
	private inputBatchApprovalStartedEventToken = ref('');
	private inputBatchDiscardStartedEventToken = ref('');
	private inputBatchConfirmationTriggeredEventToken = ref('');

	public modalStatus = computed((): ModalStatusApproveBatchEnum => {
		return store.getters.modalStatus();
	});

	public approveBatchModalStatusIsInactive = computed((): boolean => {
		return this.modalStatus.value === ModalStatusApproveBatchEnum.INACTIVE;
	});

	private inputBatchStatusBasedOnModalStatus = computed((): InputBatchStatusEnum => {
		if (this.modalStatus.value === ModalStatusApproveBatchEnum.APPROVAL_CONFIRMATION) {
			return InputBatchStatusEnum.APPROVED;
		} else {
			return InputBatchStatusEnum.DISCARDED;
		}
	});

	private selectedInputBatch = computed((): InputBatchSelection => {
		return store.getters.selectedInputBatch();
	});

	public mounted(): void {
		this.inputBatchApprovalStartedEventToken.value = EventBus.getEvent(InputBatchApprovalStartedEvent).subscribe(() =>
			store.mutations.setModalStatus(ModalStatusApproveBatchEnum.APPROVAL_CONFIRMATION)
		);
		this.inputBatchDiscardStartedEventToken.value = EventBus.getEvent(InputBatchDiscardStartedEvent).subscribe(() =>
			store.mutations.setModalStatus(ModalStatusApproveBatchEnum.DISCARD_CONFIRMATION)
		);
		this.inputBatchConfirmationTriggeredEventToken.value = EventBus.getEvent(InputBatchConfirmationTriggeredEvent).subscribe(() => {
			void this.actionOnInputBatch(this.inputBatchStatusBasedOnModalStatus.value);
		});
	}

	public unmounted(): void {
		EventBus.getEvent(InputBatchApprovalStartedEvent).unsubscribe(this.inputBatchApprovalStartedEventToken.value);
		EventBus.getEvent(InputBatchDiscardStartedEvent).unsubscribe(this.inputBatchDiscardStartedEventToken.value);
		EventBus.getEvent(InputBatchConfirmationTriggeredEvent).unsubscribe(this.inputBatchConfirmationTriggeredEventToken.value);
	}

	public onModalStatusChanged(newValue: ModalStatusApproveBatchEnum): void {
		if (newValue !== ModalStatusApproveBatchEnum.LOADING) {
			return;
		}

		const modalWrapper = document.querySelector('.ApproveBatchModalWrapper');
		if (!modalWrapper) {
			return;
		}

		modalWrapper.classList.add('LoadingModalWrapper');
	}

	private async actionOnInputBatch(action: InputBatchStatusEnum): Promise<void> {
		if (!this.selectedInputBatch.value) {
			return;
		}

		const actionIsApproval = action === InputBatchStatusEnum.APPROVED;
		this.showLoader();

		try {
			const batchIdentity = this.selectedInputBatch.value.inputBatch.identity;
			if (actionIsApproval) {
				await inputBatchService.approveInputBatch(batchIdentity);
			} else {
				await inputBatchService.discardInputBatch(batchIdentity);
			}

			store.mutations.markInputBatchAsStatus(batchIdentity, action);

			EventBus.getEvent(actionIsApproval ? InputBatchApprovedEvent : InputBatchDiscardedEvent).publish();

			this.showSuccessMessage();
		} catch (e) {
			this.showFailureMessage();
		}
	}

	private showLoader(): void {
		store.mutations.setModalStatus(ModalStatusApproveBatchEnum.LOADING);
	}

	private showSuccessMessage(): void {
		store.mutations.setModalStatus(ModalStatusApproveBatchEnum.SUCCESS);
		setTimeout(() => store.mutations.setModalStatus(ModalStatusApproveBatchEnum.INACTIVE), 1000);
	}

	private showFailureMessage(): void {
		store.mutations.setModalStatus(ModalStatusApproveBatchEnum.FAILED);
	}
}
