import { defineComponent } from 'vue';
import LoadingAnimationComponent from '@/presentation/components/loading-animation/loading-animation-class';

export default defineComponent({
	setup: () => {
		const component = new LoadingAnimationComponent();

		return {
			progress: component.progress
		};
	}
});
