import { UserSession } from './infrastructure';
import inputBatchPresenter from './presenters/input-batch-presenter';

const bootstrap = (): void => {
	if (UserSession.isValid()) {
		UserSession.keepAlive();

		void inputBatchPresenter.acquireInputBatches();
	} else {
		const error = new Error('No valid user session found.');

		error.name = 'NotAuthorizedException';
		throw error;
	}
};

export { bootstrap };
