import { defineComponent, Ref, toRef } from 'vue';
import { IComponentParams } from '@/models/i-component-params';
import ApproveBatchEntryComponent from '@/presentation/components/approve-batch-entry/approve-batch-entry-class';

export default defineComponent({
	props: {
		params: Object
	},
	setup: (props) => {
		const params = toRef(props, 'params') as Ref<IComponentParams>;
		const component = new ApproveBatchEntryComponent(params);

		return {
			inputBatch: component.inputBatch,
			onApproveBatchClicked: component.onApproveBatchClicked.bind(component),
			onDiscardBatchClicked: component.onDiscardBatchClicked.bind(component)
		};
	}
});
