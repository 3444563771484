import { Component, computed, ref, Ref } from 'vue';
import { InputBatch } from '@/models/input-batch';
import { ITable, IGridOptions, IGridApi } from '@/models/i-table';
import TheBatchesOverviewFields from './the-batches-overview-fields';
import ApproveBatchEntry from '@/presentation/components/approve-batch-entry/approve-batch-entry.vue';
import WorkOrderDetails from '@/presentation/components/work-order-details/work-order-details.vue';
import IncompleteWorkOrderTooltip from '@/presentation/components/incomplete-work-order-tooltip/incomplete-work-order-tooltip.vue';
import WorkOrderMessagePresenter from '@/presenters/work-order-message-presenter';
import EventBus from '@/event-bus/event-bus';
import { InputBatchApprovedEvent } from '@/event-bus/input-batch-approved-event';
import { InputBatchDiscardedEvent } from '@/event-bus/input-batch-discarded-event';
import store from '@/store';

export default class TheBatchesOverviewComponent {
	public table: Ref<HTMLElement | null> = ref(null);
	public fields = TheBatchesOverviewFields;
	public customCellComponents: { [key: string]: Component } = {
		ApproveBatchEntry: ApproveBatchEntry as Component,
		WorkOrderDetails: WorkOrderDetails as Component,
		customTooltip: IncompleteWorkOrderTooltip as Component
	};
	private inputBatchApprovedEventToken = ref('');
	private inputBatchDiscardedEventToken = ref('');
	private inputBatchesEnriched = false;

	public filteredInputBatches = computed((): InputBatch[] => {
		return store.getters.filteredInputBatches();
	});

	private tableComponent = computed((): ITable => {
		return this.table.value as ITable;
	});

	private gridOptions = computed((): IGridOptions | undefined => {
		if (!this.tableComponent.value) {
			return;
		}

		return this.tableComponent.value.gridOptions;
	});

	private gridApi = computed((): IGridApi | undefined => {
		if (!this.gridOptions.value) {
			return;
		}

		return this.gridOptions.value.api;
	});

	public mounted(): void {
		if (!this.gridOptions.value) {
			return;
		}

		this.gridOptions.value.headerHeight = 40;

		this.enrichBatchesWithWorkOrderIncompleteMessages();

		this.inputBatchApprovedEventToken.value = EventBus.getEvent(InputBatchApprovedEvent).subscribe(this.reloadTable.bind(this));
		this.inputBatchDiscardedEventToken.value = EventBus.getEvent(InputBatchDiscardedEvent).subscribe(this.reloadTable.bind(this));
	}

	public unmounted(): void {
		EventBus.getEvent(InputBatchApprovedEvent).unsubscribe(this.inputBatchApprovedEventToken.value);
		EventBus.getEvent(InputBatchDiscardedEvent).unsubscribe(this.inputBatchDiscardedEventToken.value);
	}

	public enrichBatchesWithWorkOrderIncompleteMessages(): void {
		if (this.filteredInputBatches.value.length === 0 || this.inputBatchesEnriched) {
			return;
		}

		const workOrderMessagePresenter = new WorkOrderMessagePresenter(this.filteredInputBatches.value);
		void workOrderMessagePresenter.setWorkOrderIncompleteMessages(this.reloadTable.bind(this));
		this.inputBatchesEnriched = true;
	}

	private reloadTable(): void {
		if (!this.gridApi.value) {
			return;
		}

		this.gridApi.value.redrawRows();
	}
}
