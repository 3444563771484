import { reactive } from 'vue';
import { IState } from '@/models/store/state.interface';
import { EmptyInputBatchSelection } from '@/models/empty-input-batch-selection';
import { ModalStatusApproveBatchEnum } from '@/models/modal-status-approve-batch-enum';

const state: any = reactive({
	inputBatches: [],
	filteredInputBatches: [],
	selectedInputBatch: new EmptyInputBatchSelection(),
	modalStatus: ModalStatusApproveBatchEnum.INACTIVE,
	loadingDetails: {
		loadingStarted: false,
		batchesTotal: 0,
		batchesLoaded: 0
	},
	inputBatchSearchQuery: '',
	standAloneLogin: false
});

export default state as IState;
