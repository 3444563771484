import { InputBatchStatus as InputBatchStatusEnum } from '@studyportals/sp-lurch-interface';

export class InputBatchStatus {
	public static obtainForValues(values: readonly InputBatchStatusEnum[]): InputBatchStatus[] {
		return values.map((_) => InputBatchStatus.obtain(_));
	}

	public static obtain(value: InputBatchStatusEnum): InputBatchStatus {
		switch (value) {
			case InputBatchStatusEnum.SUBMITTED:
				return new InputBatchStatus('Uploaded', InputBatchStatusEnum.SUBMITTED);
			case InputBatchStatusEnum.APPROVED:
				return new InputBatchStatus('Approved', InputBatchStatusEnum.APPROVED);
			case InputBatchStatusEnum.DISCARDED:
				return new InputBatchStatus('Discarded', InputBatchStatusEnum.DISCARDED);
		}

		throw new Error('Unknown status');
	}

	public constructor(
		public readonly name: string,
		public readonly value: InputBatchStatusEnum
	) {}
}
