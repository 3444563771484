import { computed, Ref } from 'vue';
import EventBus from '@/event-bus/event-bus';
import { IComponentParams } from '@/models/i-component-params';
import { InputBatchApprovalStartedEvent } from '@/event-bus/input-batch-approval-started-event';
import { InputBatchDiscardStartedEvent } from '@/event-bus/input-batch-discard-started-event';
import { InputBatch } from '@/models/input-batch';

export default class ApproveBatchEntryComponent {
	constructor(public params: Ref<IComponentParams>) {}

	public inputBatch = computed((): InputBatch => {
		return this.params.value.data;
	});

	public onApproveBatchClicked(): void {
		EventBus.getEvent(InputBatchApprovalStartedEvent).publish(this.inputBatch.value);
	}

	public onDiscardBatchClicked(): void {
		EventBus.getEvent(InputBatchDiscardStartedEvent).publish(this.inputBatch.value);
	}
}
