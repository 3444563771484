import { IntakeStageType as RecruitmentStageEnum } from '@studyportals/sp-lurch-interface';

export class RecruitmentStage {
	public static obtainForValues(values: readonly RecruitmentStageEnum[]): RecruitmentStage[] {
		return values.map((_) => RecruitmentStage.obtain(_));
	}

	public static obtain(value: RecruitmentStageEnum): RecruitmentStage {
		switch (value) {
			case RecruitmentStageEnum.LEAD:
				return new RecruitmentStage('Leads', RecruitmentStageEnum.LEAD);
			case RecruitmentStageEnum.APPLICATION:
				return new RecruitmentStage('Applications', RecruitmentStageEnum.APPLICATION);
			case RecruitmentStageEnum.ACCEPTED:
				return new RecruitmentStage('Offers', RecruitmentStageEnum.ACCEPTED);
			case RecruitmentStageEnum.ENROLMENT:
				return new RecruitmentStage('Enrolments', RecruitmentStageEnum.ENROLMENT);
		}

		throw new Error('Unknown recruitment stage');
	}

	public constructor(
		public readonly name: string,
		public readonly value: RecruitmentStageEnum
	) {}
}
