export class Month {
	public static all(): readonly Month[] {
		return [
			this.january(),
			this.february(),
			this.march(),
			this.april(),
			this.may(),
			this.june(),
			this.july(),
			this.august(),
			this.september(),
			this.october(),
			this.november(),
			this.december()
		];
	}

	public static january(): Month {
		return (this._january = this._january || new Month('January', 1));
	}

	public static february(): Month {
		return (this._february = this._february || new Month('February', 2));
	}

	public static march(): Month {
		return (this._march = this._march || new Month('March', 3));
	}

	public static april(): Month {
		return (this._april = this._april || new Month('April', 4));
	}

	public static may(): Month {
		return (this._may = this._may || new Month('May', 5));
	}

	public static june(): Month {
		return (this._june = this._june || new Month('June', 6));
	}

	public static july(): Month {
		return (this._july = this._july || new Month('July', 7));
	}

	public static august(): Month {
		return (this._august = this._august || new Month('August', 8));
	}

	public static september(): Month {
		return (this._september = this._september || new Month('September', 9));
	}

	public static october(): Month {
		return (this._october = this._october || new Month('October', 10));
	}

	public static november(): Month {
		return (this._november = this._november || new Month('November', 11));
	}

	public static december(): Month {
		return (this._december = this._december || new Month('December', 12));
	}

	public static forValue(value: number): Month {
		switch (value) {
			case 1:
				return this.january();
			case 2:
				return this.february();
			case 3:
				return this.march();
			case 4:
				return this.april();
			case 5:
				return this.may();
			case 6:
				return this.june();
			case 7:
				return this.july();
			case 8:
				return this.august();
			case 9:
				return this.september();
			case 10:
				return this.october();
			case 11:
				return this.november();
			case 12:
				return this.december();
		}

		throw new Error(`Invalid month value ${value}`);
	}
	private static _january: Month | undefined;
	private static _february: Month | undefined;
	private static _march: Month | undefined;
	private static _april: Month | undefined;
	private static _may: Month | undefined;
	private static _june: Month | undefined;
	private static _july: Month | undefined;
	private static _august: Month | undefined;
	private static _september: Month | undefined;
	private static _october: Month | undefined;
	private static _november: Month | undefined;
	private static _december: Month | undefined;

	private constructor(
		public readonly name: string,
		public readonly value: number
	) {}
}
