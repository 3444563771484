import { InputBatch } from './input-batch';

export class InputBatchSelection {
	private _inputBatch: InputBatch | undefined;

	public get hasInputBatch(): boolean {
		return undefined !== this._inputBatch;
	}

	public get inputBatch(): InputBatch {
		if (undefined === this._inputBatch) {
			throw new Error('An input batch was not selected');
		}

		return this._inputBatch;
	}

	public constructor(inputBatch?: InputBatch) {
		this._inputBatch = inputBatch;
	}
}
