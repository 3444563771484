import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "TheMatchesOverview" }
const _hoisted_2 = { class: "TableActionsBar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInputBatchesBar = _resolveComponent("SearchInputBatchesBar")!
  const _component_BatchesExport = _resolveComponent("BatchesExport")!
  const _component_ag_grid_vue = _resolveComponent("ag-grid-vue")!
  const _component_ApproveBatch = _resolveComponent("ApproveBatch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SearchInputBatchesBar),
      _createVNode(_component_BatchesExport)
    ]),
    _createVNode(_component_ag_grid_vue, {
      class: "MatchesOverviewTable ag-theme-material",
      columnDefs: _ctx.fields,
      rowData: _ctx.filteredInputBatches,
      components: _ctx.customCellComponents,
      rowSelection: "single",
      tooltipShowDelay: 0,
      ref: "table"
    }, null, 8 /* PROPS */, ["columnDefs", "rowData", "components"]),
    _createVNode(_component_ApproveBatch)
  ]))
}