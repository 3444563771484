import { defineComponent } from 'vue';
import SearchInputBatchesBarComponent from '@/presentation/components/search-input-batches-bar/search-input-batches-bar-class';

export default defineComponent({
	setup: () => {
		const component = new SearchInputBatchesBarComponent();

		return {
			searchBar: component.searchBar,
			isRevealed: component.isRevealed,
			isExpanded: component.isExpanded,
			isActive: component.isActive,
			updateSearchQuery: component.updateSearchQuery.bind(component),
			expandSearchBar: component.expandSearchBar.bind(component),
			hideSearchBar: component.hideSearchBar.bind(component),
			clearSearchQuery: component.clearSearchQuery.bind(component)
		};
	}
});
