import { computed, Ref } from 'vue';
import { InputBatch } from '@/models/input-batch';
import { ICustomComponentParams } from '@/models/i-component-params';

export default class IncompleteWorkOrderTooltipComponent {
	constructor(public params: Ref<ICustomComponentParams>) {}

	public workOrderTooltipMessage = computed((): string => {
		if (!this.rowData.value) {
			return '';
		}

		return this.rowData.value.workOrderTooltipMessage;
	});

	private rowData = computed((): false | InputBatch => {
		// Make sure that expected functionality to retrieve row data is available.
		if (
			!this.params.value.api ||
			typeof this.params.value.rowIndex !== 'number' ||
			typeof this.params.value.api.getDisplayedRowAtIndex !== 'function'
		) {
			return false;
		}

		// See if row data of the expected format is available.
		const rowContent = this.params.value.api.getDisplayedRowAtIndex(this.params.value.rowIndex);
		if (!rowContent || !(rowContent.data instanceof InputBatch)) {
			return false;
		}

		return rowContent.data;
	});
}
