import state from './state';
import EventBus from '@/event-bus/event-bus';
import { InputBatchApprovalStartedEvent } from '@/event-bus/input-batch-approval-started-event';
import { InputBatchDiscardStartedEvent } from '@/event-bus/input-batch-discard-started-event';
import { IMutations } from '@/models/store/mutations.interface';
import { EmptyInputBatchSelection } from '@/models/empty-input-batch-selection';
import { InputBatchSelection } from '@/models/input-batch-selection';

const mutations: IMutations = {
	updateInputBatches: (batches) => {
		state.inputBatches = batches;
	},
	filterInputBatches: () => {
		let batches = state.inputBatches;

		// Only filter based on a search query if the search bar is being used.
		if (state.inputBatchSearchQuery.length) {
			batches = batches.filter((batch) => {
				// Search on organisation name or id.
				return (batch.organisationName && batch.organisationName.toLowerCase().includes(state.inputBatchSearchQuery))
					|| (batch.organisationIdentity && batch.organisationIdentity.toLowerCase().includes(state.inputBatchSearchQuery));
			});
		}

		state.filteredInputBatches = batches;
	},
	markInputBatchAsStatus: (id, status) => {
		const relevantBatch = state.inputBatches.find((batch) => batch.identity === id);
		if (!relevantBatch) {
			return;
		}

		relevantBatch.updateStatus(status);
	},
	selectInputBatch: (batch) => {
		state.selectedInputBatch = new InputBatchSelection(batch);
	},
	deselectInputBatch: () => {
		state.selectedInputBatch = new EmptyInputBatchSelection();
	},
	setModalStatus: (status) => {
		state.modalStatus = status;
	},
	markLoadingAsStarted: () => {
		state.loadingDetails.loadingStarted = true;
	},
	setTotalAmountOfLoadedBatches: (amount) => {
		state.loadingDetails.batchesTotal = amount;
	},
	updateAmountOfLoadedBatches: (amount) => {
		state.loadingDetails.batchesLoaded = amount;
	},
	updateInputBatchSearchQuery: (query) => {
		state.inputBatchSearchQuery = query;
	},
	setStandAloneLogin: (standAloneLogin) => {
		state.standAloneLogin = standAloneLogin;
	},
	subscribeToEventBus: () => {
		EventBus.getEvent(InputBatchApprovalStartedEvent).subscribe((inputBatch) => mutations.selectInputBatch(inputBatch));
		EventBus.getEvent(InputBatchDiscardStartedEvent).subscribe((inputBatch) => mutations.selectInputBatch(inputBatch));
	}
};

export default mutations;
