import { Component, defineComponent } from 'vue';
import TheBatchesOverview from '@/presentation/components/the-batches-overview/the-batches-overview.vue';
import LoadingAnimation from '@/presentation/components/loading-animation/loading-animation.vue';
import LogOut from '@/stand-alone/log-out/log-out.vue';
import AppComponent from '@/presentation/app-class';

export default defineComponent({
	components: {
		TheBatchesOverview: TheBatchesOverview as Component,
		LoadingAnimation: LoadingAnimation as Component,
		LogOut: LogOut as Component
	},
	setup: () => {
		const component = new AppComponent();

		return {
			loadingStarted: component.loadingStarted,
			loadingFinished: component.loadingFinished,
			standAloneLogin: component.standAloneLogin
		};
	}
});
