import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ApproveBatch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApproveBatchModal = _resolveComponent("ApproveBatchModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.approveBatchModalStatusIsInactive)
      ? (_openBlock(), _createBlock(_component_ApproveBatchModal, { key: 0 }))
      : _createCommentVNode("v-if", true)
  ]))
}