import { Component, defineComponent, onMounted, onUnmounted, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import SearchInputBatchesBar from '@/presentation/components/search-input-batches-bar/search-input-batches-bar.vue';
import BatchesExport from '@/presentation/components/batches-export/batches-export.vue';
import ApproveBatch from '@/presentation/components/approve-batch/approve-batch.vue';
import TheBatchesOverviewComponent from '@/presentation/components/the-batches-overview/the-batches-overview-class';

export default defineComponent({
	components: {
		BatchesExport: BatchesExport as Component,
		ApproveBatch: ApproveBatch as Component,
		SearchInputBatchesBar: SearchInputBatchesBar as Component,
		AgGridVue
	},
	setup: () => {
		const component = new TheBatchesOverviewComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		watch(component.filteredInputBatches, component.enrichBatchesWithWorkOrderIncompleteMessages.bind(component));

		return {
			table: component.table,
			fields: component.fields,
			filteredInputBatches: component.filteredInputBatches,
			customCellComponents: component.customCellComponents
		};
	}
});
